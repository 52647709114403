<template>
  <localization :language="'ru-RU'">
    <intl :locale="'ru'">
      <CRow class="table-actions-row">
        <v-col sm="12" cols="12" lg="4" class="pl-0">
          <breadcrumbs :items="crumbs" />
        </v-col>
        <v-col
          sm="12"
          md="12"
          lg="8"
          class="action-btns d-flex justify-end align-center flex-column flex-md-row"
        ></v-col>
      </CRow>
      <Grid
          ref="grid"
          class="request"
          :resizable="true"
          :sortable="true"
          :filterable="true"
          :pageable="gridPageable"
          :filter="filter"
          @datastatechange="dataStateChange"
          :take="take"
          :skip="skip"
          :sort="sort"
          :page-size="pageSize"
          :total="total"
          :data-items="dataResult"
          :columns="columns"
          @expandchange="expandChange"
          @sortchange="sortChange"
      >
        <template v-slot:status_filter>
          <drop-down-list
            class="status-filter"
            text-field="name"
            data-item-key="key"
            :default-item="{key: null, name: 'Все'}"
            :data-items="WRITE_DOWN_POINTS_STATUSES"
            @change="selectFilter('status', $event.value.key)"
          />
        </template>
        <template v-slot:date_filter>
          <div class="d-flex align-end custom">
            <datepicker
              :default-show="false"
              class="date-filter"
              :start-date-input-settings="startDateInputSettings"
              :value="value"
              :end-date-input-settings="endDateInputSettings"
              @change="dateChangeHandler"
            />
            <button
              @click="clearDates"
              title="Clear"
              type="button"
              class="k-button k-button-icon k-clear-button-visible"
            >
              <span class="k-icon k-i-filter-clear"></span>
            </button>
          </div>
        </template>
        <template v-slot:status="{ props: { dataItem } }">
          <td>
            {{ WRITE_DOWN_POINTS_STATUSES.find((status) => +status.key === +dataItem.status).name }}
          </td>
        </template>
      </Grid>
      <v-row class="mt-3 mb-2">
        <v-col cols="12" class="pl-0 d-flex justify-end">
          <CButton
            class="export-excel-btn"
            color="success"
            @click="downloadReport"
          >
            <i class="mr-2 fa fa-save"></i>
            <span>Экспорт в Excel</span>
          </CButton>
        </v-col>
      </v-row>
    </intl>
  </localization>
</template>

<script>
import '@/views/grid_elements/translations';
import moment from "moment";
import {IntlProvider, LocalizationProvider} from "@progress/kendo-vue-intl";
import breadcrumbs from "@/views/components/breadcrumbs.vue";
import { DateRangePicker } from "@progress/kendo-vue-dateinputs";
import TableMixin from "@/views/grid_elements/mixins/TableMixin";
import ResizableTableMixin from "@/views/grid_elements/mixins/ResizableTableMixin";
import WRITE_DOWN_POINTS_STATUSES from "@/config/request_write_down_points_statuses";
import api from "@/api";
import {toDataSourceRequestString} from "@progress/kendo-data-query";
import {TYPE_ACCOUNT, TYPE_ACT, TYPE_OTHER} from "@/config/request_write_down_points_docs_types";
import {DropDownList} from "@progress/kendo-vue-dropdowns";

export default {
  name: 'request-write-down-points-statistic',
  components: {
    DropDownList,
    datepicker: DateRangePicker,
    localization: LocalizationProvider,
    intl: IntlProvider,
    breadcrumbs,
  },
  mixins: [TableMixin, ResizableTableMixin],
  data() {
    return {
      WRITE_DOWN_POINTS_STATUSES,
      skip: 0,
      take: 10,
      pageSize: 10,
      total: 10,
      value: {
        start: null,
        end: null,
      },
      startDateInputSettings: {
        label: 'От',
        format: 'dd.MM.yyyy',
      },
      endDateInputSettings: {
        format: 'dd.MM.yyyy',
        label: 'До',
      },
      gridPageable: { pageSizes: true },
      columns: [
        {
          field: 'request_number',
          title: 'Номер заявки',
          type: 'text',
          filter: 'text',
          minWidth: 160,
          width: '160px',
        },
        {
          field: 'date_request',
          title: 'Дата создания заявки',
          type: 'date',
          filter: 'date',
          format: '{0:d}',
          minWidth: 440,
          width: '440px',
          filterCell: 'date_filter'
        },
        {
          field: 'status',
          title: 'Статус',
          cell: 'status',
          filterCell: 'status_filter',
          minWidth: 160,
          width: '160px',
        },
        {
          field: 'dealer_name',
          title: 'Дилер',
          type: 'text',
          minWidth: 250,
          width: '250px',
        },
        {
          field: 'dealer_unique_id',
          title: 'КССС',
          type: 'text',
          filter: 'text',
          minWidth: 160,
          width: '160px',
        },
        {
          field: 'points_debited',
          title: 'Сумма списания',
          type: 'number',
          filter: 'text',
          minWidth: 160,
          width: '160px',
        },
        {
          field: 'description',
          title: 'Основание для списания',
          type: 'text',
          filter: 'text',
          minWidth: 250,
          width: '250px',
        },
        {
          field: 'dealer_fio',
          title: 'ФИО',
          type: 'text',
          minWidth: 250,
          width: '250px',
        },
        {
          field: 'dealer_phone',
          title: 'Номер телефона',
          type: 'text',
          minWidth: 250,
          width: '250px',
        },
        {
          field: 'dealer_email',
          title: 'E-mail',
          type: 'text',
          minWidth: 250,
          width: '250px',
        },
      ],
    }
  },
  methods: {
    dateChangeHandler({value}) {
      this.value.start = value.start;
      this.value.end = value.end;
      const dateStart = moment(new Date(value.start)).format('YYYY.MM.DD');
      const dateEnd = moment(new Date(value.end)).format('YYYY.MM.DD');
      if (dateEnd === '1970.01.01') {
        this.setFilter('date', 'gte', dateStart);
      } else if (dateStart === dateEnd) {
        this.removeFilter('date');
        this.setFilter('date', 'gtedatetime', dateStart);
      } else {
        this.removeFilter('date');
        const obj = [
          {
            field: 'date',
            operator: 'gte',
            value: dateStart,
          },
          {
            field: 'date',
            operator: 'lte',
            value: dateEnd,
          },
        ];
        this.setMultipleFilter('date', obj);
      }
      this.updateTable(true);
    },
    fetchData: api.request_write_down_points.getRequestsWriteDownPoints,
    clearDates() {
      this.value.start = null;
      this.value.end = null;
      this.removeFilter('date');
      this.removeFilter('date');
      this.updateTable(true);
    },
    async downloadReport() {
      try {
        const response = await api.request_write_down_points.getRequestsWriteDownPointsReport(
          toDataSourceRequestString({
            filter: this.filter,
            sort: this.sort,
          })
        );

        const fileURL = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', `Заявки на списание.xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
        document.body.removeChild(fileLink);
      } catch (e) {
        console.warn(e);
      }
    },
  },

  watch: {
    dataResult() {
      this.dataResult.data = this.dataResult.data.map((result) => ({
        ...result,
        act_files: result.docs.filter((doc) => doc.file_type === TYPE_ACT),
        other_files: result.docs.filter((doc) => doc.file_type === TYPE_OTHER),
        account_files: result.docs.filter((doc) => doc.file_type === TYPE_ACCOUNT),
      }));
    }
  },
  async mounted() {
    await this.getData()
  }
}
</script>